import React from 'react';
import {RiMapPin2Line, RiPhoneLine, RiMailLine} from 'react-icons/ri'

import './footer.css';
import logo from '../../assets/Digi-Ants Logo.png'

const Footer = () => {
  return (
    <div className='section__padding' style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
      <div className='da__footer'>
        <div className='da__footer-logo'>
          <img className='logo' src={logo} alt='logo'/>
          <p>Digi-Ants Co., Ltd</p>
          <p>Reg. No.: 0317354897</p>
        </div>

        <div className='da__footer-links'>
          <div className='da__footer-links_item pointer' onClick={() => window.location = 'mailto:contact@digi-ants.com'}>
            <RiMailLine color='#fff' size={27}/>
            <p >contact@digi-ants.com</p>
          </div>

          <div className='da__footer-links_item'>
            <RiMapPin2Line color='#fff' size={27}/>
            <p>No. 37, Str. 9, Thu Duc, Ho Chi Minh City, Vietnam</p>
          </div>

          <div className='da__footer-links_item'>
            <RiPhoneLine color='#fff' size={27}/>
            <p>+84-855500720</p>
          </div>
        </div>
      </div>

      <div className='da__footer-copyright' >
        <p>Copyright © 2023 by Digi-Ants Co., Ltd </p>
      </div>
    </div>
    
  )
}

export default Footer